import React, { useEffect, useState, useRef } from "react";
import { FaWindowClose } from "react-icons/fa";
import "../Styles/Bar.css";
import { Link } from "react-router-dom";

const Bar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const handleCloseButtonClick = (event) => {
    if (event.button === 0) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="golo">
      <div
        className={`hamburger-button ${isMenuOpen ? "open" : ""}`}
        onClick={handleMenuClick}
      >
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
      <nav ref={menuRef} className={`navigation ${isMenuOpen ? "active" : ""}`}>
        <ul className="mt-3">
          <Link to="/blog" onClick={handleMenuClick}>
            <li className="fs-4">Blog Post</li>
          </Link>
          <Link to="/tutorialseries" onClick={handleMenuClick}>
            <li
              className="fs-4 mb-3"
              style={{ transform: "translateY(-10px)" }}
            >
              TutorialSeries
            </li>
          </Link>
        </ul>
      </nav>
    </div>
  );
};

export default Bar;
