import React, { useEffect, useState } from "react";
import { MdEmail } from "react-icons/md";
import { BsLinkedin } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../../config/Config";
import { FaInstagramSquare } from "react-icons/fa";

function Footer() {
  const [data, setData] = useState([]);

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setData(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    fetchConfiguration();
  }, []);

  console.log(data, "check data");

  // Safe Access
  const linkedinLink = data[0]?.linkdin_page_link;
  const Email = data[0]?.contact_email;
  const Facebook = data[0]?.facebook_page_link;
  const Instagram = data[0]?.instagram_page_link;

  return (
    <div className="mb-2">
      <div className="row background_Color text_Color font_family">
        <div className="col-sm-4 mt-4 ">
          <center>
            <span className="fs-6 ">
              <Link to="/" style={{ textDecoration: "none" }}>
                <b className="text_Color">HOME</b>
              </Link>
            </span>{" "}
            <span className="ms-2 fs-6">
              <Link to="/about" style={{ textDecoration: "none" }}>
                <b className="text_Color">ABOUT</b>
              </Link>
            </span>
            <span className="ms-2 fs-6">
              <Link to="/contact" style={{ textDecoration: "none" }}>
                <b className="text_Color">CONTACT</b>
              </Link>
            </span>
          </center>
        </div>
        <div className="col-sm-4">
          <div className="text-center mt-3 p-1">
            <b>
              © 2024 Copyright:XTeam Consultants India . Pvt . Ltd (Lucknow)
            </b>
          </div>
        </div>
        <div className="col-sm-4 col-md-4 p-3">
          <center>
            {Email ? (
              <a href={Email}>
                <MdEmail className="icne" />
              </a>
            ) : null}{" "}
            {linkedinLink ? (
              <a href={linkedinLink}>
                <BsLinkedin className="icne" />
              </a>
            ) : null}{" "}
            {Facebook ? (
              <a href={Facebook}>
                <FaFacebook className="icne" />
              </a>
            ) : null}{" "}
            {Instagram ? (
              <a href={Instagram}>
                <FaInstagramSquare className="icne" />
              </a>
            ) : null}
          </center>
        </div>
      </div>
    </div>
  );
}

export default Footer;
