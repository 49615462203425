import React, { useState, useEffect } from "react";
import "../Styles/Contect.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config/Config";
import { Helmet } from "react-helmet";
export default function Contact() {
  const [configuration, setConfiguration] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    comment: "",
  });

  const [errors, setErrors] = useState({});

  const contact = () => {
    fetch(`${config.API_BLOG}contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((result) => result.json())
      .then((res) => {
        toast.success(res.message || "Comment submitted successfully!");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error("An error occurred. Please try again later.");
      });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      contact();
      setFormData({
        name: "",
        email: "",
        number: "",
        comment: "",
      });
    }
  };

  const validateForm = (data) => {
    const validationErrors = {};

    if (!data.name) {
      validationErrors.name = "Name is required.";
    }

    if (!data.email) {
      validationErrors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      validationErrors.email = "Please enter a valid email address.";
    }

    if (!data.number) {
      validationErrors.number = "Phone number is required.";
    } else if (!isValidNumber(data.number)) {
      validationErrors.number = "Please enter a valid 10-digit phone number.";
    }

    if (!data.comment) {
      validationErrors.comment = "Comment is required.";
    }

    return validationErrors;
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidNumber = (number) => {
    return /^[0-9]{10}$/.test(number);
  };

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    fetchConfiguration();
  }, []);

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  return (
    <div className="container-fluid mt-5 mb-5">
      {/* ***************************************** */}

      <Helmet>
        <title>Contact</title>
        <meta name="default_meta_title" content={metaTitle} />
        <meta name="default_meta_keywords" content={metaKeywords} />
        <meta name="default_meta_description" content={metaDescription} />
      </Helmet>

      {/* ************************************************ */}
      <div className="row con">
        <div className="col-sm-4 "></div>
        <div className="col-sm-4 box">
          <p className="box1">CONTACT US</p>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                type="text"
                placeholder="Name"
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <br />
            <div>
              <input
                type="email"
                placeholder="Email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <br />
            <div>
              <input
                type="text"
                placeholder="Phone Number"
                className={`form-control ${errors.number ? "is-invalid" : ""}`}
                name="number"
                value={formData.number}
                onChange={handleChange}
              />
            </div>
            <br />
            <div>
              <textarea
                placeholder="Comment"
                className={`form-control ${errors.comment ? "is-invalid" : ""}`}
                name="comment"
                value={formData.comment}
                onChange={handleChange}
              />
            </div>
            <br />
            <center>
              <button
                type="submit"
                style={{
                  height: "40px",
                  width: "200px",
                  borderRadius: "10px",
                  background: "#173a9b",
                  color: "white",
                }}
              >
                Submit
              </button>
            </center>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
