import React, { useState, useEffect } from "react";
import "../Styles/About.css";
import config from "../../config/Config";
import { Helmet } from "react-helmet";
export default function About() {
  const [configuration, setConfiguration] = useState([]);
  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    fetchConfiguration();
  }, []);

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  return (
    <div className="Container-fluid">
      {/* ***************************************** */}

      <Helmet>
        <title>About</title>
        <meta name="default_meta_title" content={metaTitle} />
        <meta name="default_meta_keywords" content={metaKeywords} />
        <meta name="default_meta_description" content={metaDescription} />
      </Helmet>

      {/* ************************************************ */}

      <center style={{ fontSize: "38px", fontFamily: "sofia" }}>
        <b>
          About<b style={{ color: "#173a9b" }}> Us</b>
        </b>
        <hr className="w-25 mx-auto" />
      </center>
      <div className="row">
        <div className="col-sm-6 p-4">
          <div className="abo"></div>
        </div>
        <div className="col-sm-6 p-4">
          <div className="abo1" style={{ fontFamily: "sofia" }}>
            <b className="fs-3" style={{ color: "#173a9b" }}>
              {" "}
              Meaning of Motivation
            </b>
            <br />
            <span className="fs-5">
              Motivation is something that cannot be understood with words but
              with practice. It means to be moved by something so strongly that
              it becomes an inspiration for you. Furthermore, it is a discipline
              that helps you to achieve your life goals and also helps to be
              successful in life. Besides, it the most common practice that
              everyone does whether it is your boss in office or a school
              teacher or a university professor everyone motivates others in a
              way or other.
            </span>
            <br />
            <span>
              <b className="fs-3" style={{ color: "#173a9b" }}>
                {" "}
                Self-motivation
              </b>
              <br />
              It refers to the power of someone to stay motivated without the
              influence of other situations and people. Furthermore,
              self-motivated people always find a way to reason and strength to
              complete a task. Also, they do not need other people to encourage
              them to perform a challenging task.
            </span>
            <br />
            <span>
              <b className="fs-3" style={{ color: "#173a9b" }}>
                {" "}
                Motivation by others
              </b>
              <br />
              This motivation requires help from others as the person is not
              able to maintain a self-motivated state. In this, a person
              requires encouragement from others. Also, he needs to listen to
              motivational speeches, a strong goal and most importantly and
              inspiration.
            </span>

            <span>
              Motivation is very important for the overall development of the
              personality and minimport About from './About'; d of the people.
              It also puts a person in action and in a competitive state.
              Furthermore, it improves efficiency and desire to achieve the
              goal. It leads to stability and improvement in work. Above all, it
              satisfies a person’s needs and to achieve his/her goal. It helps
              the person to fight his negative attitude.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
