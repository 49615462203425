import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import LessonSidedata from "./LessonSidedata";

const Nav = styled.div`
  background: #173a9b;
  height: 250px;
  // display: flex;
  width: 30px;
  position: fixed;
`;

const NavIcon = styled(Link)`
  margin-right: 2rem;
  font-size: 2rem;
  //   height: 50px;
  //   display: flex;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #173a9b;
  width: 250px;
  min-height: 330px;
  margin-top: 340px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  cursor: pointer;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const LessonSidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav onClick={showSidebar}>
          <span className="text-light ms-2 sidebar_span">S</span>
          <br />
          <span className="text-light ms-2 sidebar_span">I</span>
          <br />
          <span className="text-light ms-2 sidebar_span">D</span>
          <br />
          <span className="text-light ms-2 sidebar_span">E</span>
          <br />
          <span className="text-light ms-2 sidebar_span">B</span>
          <br />
          <span className="text-light ms-2 sidebar_span">A</span>
          <br />
          <span className="text-light ms-2 sidebar_span">R</span>
          <br />
        </Nav>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            <LessonSidedata />
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default LessonSidebar;
