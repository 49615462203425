import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import { dataFormatter } from "../../constant/condition";
import config from "../../config/Config";
import { Helmet } from "react-helmet";
export default function Blogpost() {
  const [configuration, setConfiguration] = useState([]);
  const [data, setData] = useState([]);
  const { tag, id, year, month } = useParams();
  function demo2() {
    let url;

    if (tag) {
      url = `${config.API_BLOG}getdata?tag=${tag}`;
      console.log(url);
    } else if (id) {
      url = `${config.API_BLOG}getdata?key=${id}`;
      console.log(url);
    } else if (year && month) {
      url = `${config.API_BLOG}getdata?year=${year}&month=${month}`;
      console.log(url);
    } else {
      url = `${config.API_BLOG}getdata`;
      console.log(url);
    }

    fetch(url).then((result) => {
      result.json().then((res) => {
        setData(res);
      });
    });
  }

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    demo2();
    fetchConfiguration();
  }, [id, tag, year, month]);

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  const renderHTML = (rawHTML) => {
    return { __html: rawHTML };
  };

  //const filterpostData = records.filter((item) => item.post_status == 1);

  return (
    <div className="container-fluid">
      {/* ***************************************** */}

      <Helmet>
        <title>Blogs</title>
        <meta name="default_meta_title" content={metaTitle} />
        <meta name="default_meta_keywords" content={metaKeywords} />
        <meta name="default_meta_description" content={metaDescription} />
      </Helmet>

      {/* ************************************************ */}
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <center
            style={{
              fontSize: "38px",
              fontFamily: "sofia",
            }}
          >
            <b>
              Bl<b style={{ color: "#173a9b" }}>ogs</b>
            </b>
            <hr className="w-25 mx-auto" />
          </center>
          <div className="row">
            {data.map((item) => {
              const newTypeDate = dataFormatter(item.created_at);
              const limitedContent = (() => {
                const parser = new DOMParser();
                const plainText =
                  parser.parseFromString(item.post_content, "text/html").body
                    .textContent || "";
                return (
                  plainText.split(/\s+/).slice(0, 40).join(" ") +
                  (plainText.split(/\s+/).length > 40 ? "..." : "")
                );
              })();

              return (
                <div className="col-md-12 col-lg-12 col-xl-6 mt-2 mb-2">
                  <div className="card mt-2 p-3">
                    <span state={{ item }} className="blogs text-dark">
                      <b>{item.post_title}</b>
                    </span>
                    <div className="row">
                      <div className="col-sm-3  mt-2">
                        <center className="p-2">
                          <img
                            // className="w-100 img-fluid "
                            src={item.post_banner_b64}
                            style={{ height: "100px", width: "150px" }}
                            className="img-fluid"
                            alt={item.post_banner_b64}
                          />
                        </center>
                      </div>
                      <div className="col-sm-9 mt-2">
                        <span
                          style={{
                            color: "black",
                            fontFamily: "sofia",
                            textAlign: "justify",
                          }}
                          dangerouslySetInnerHTML={renderHTML(limitedContent)}
                        ></span>

                        <div className="row">
                          <Link
                            to={`/read/${item.post_id}`}
                            state={{ item }}
                            style={{
                              height: "20px",
                              lineHeight: "8px",
                              fontSize: "15px",
                              textDecoration: "none",
                            }}
                          >
                            <b style={{ float: "right", fontFamily: "sofia" }}>
                              >Read More
                            </b>
                          </Link>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-2">
                        <center>
                          <div
                            className="card-header"
                            style={{
                              minHeight: "35px",
                              lineHeight: "20px",
                              background: "#173a9b",
                            }}
                          >
                            <div className="row text-center text-light font_family">
                              <div className="col-sm-4">
                                <p
                                  className="card-text"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <b> By:</b> {item.name}
                                </p>
                              </div>
                              <div className="col-sm-4">
                                <p className="card-text ">
                                  <b> On:</b> {newTypeDate}
                                </p>
                              </div>
                              <div className="col-sm-4">
                                <p
                                  className="card-text text-light"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <b>Tags:</b> {item.tags}
                                </p>
                              </div>
                            </div>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-sm-1"></div>
      </div>

      <div className="side1">
        <Sidebar />
      </div>
    </div>
  );
}
