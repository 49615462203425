import React from "react";
import TechnologySiderbar from "./TechnologySiderbar";

export default function TutorialSideData({ hideSidebar }) {
  return (
    <div>
      <TechnologySiderbar hideSidebar={hideSidebar} />
    </div>
  );
}
