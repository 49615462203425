import React from "react";
import "../Styles/NotFound.css"; // You can create a CSS file for styling
import { Link } from "react-router-dom";
import { FcSearch } from "react-icons/fc";
const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <span style={{ fontSize: "100px" }}>
          <FcSearch />
        </span>
        <h1>404</h1>
        <b className="fs-4 text-danger">Data Not Found</b>
        <br />
        <p>Oops! The page you are looking for doesn't exist.</p>
        <Link to="/">
          <button className="go-home-button">Go to Home</button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
