import React, { useState, useEffect } from "react";
import PopupExample from "./PopupExample";
import { useParams } from "react-router-dom";
import config from "../../config/Config";
export default function Comment() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  function demo3() {
    fetch(`${config.API_BLOG}comments`)
      .then((response) => response.json())
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }
  useEffect(() => {
    // window.localStorage.setItem('st',JSON.stringify(id))
    demo3();
  }, []);

  const filteredData = data.filter(
    (item) => item.comment_status == 1 && item.comment_post_id == id
  );

  return (
    <div>
      <center style={{ fontFamily: "sofia" }}>
        <span className="fs-2">Comments</span>
        <div>
          {loading ? (
            <p>Loading...</p>
          ) : filteredData.length === 0 ? (
            <p>.....</p>
          ) : (
            <ul>
              {filteredData.map((item) => (
                <div key={item.id}>
                  <div>Name: {item.comment_author_name}</div>
                  <div>Comment: {item.comment_content}</div>
                  <PopupExample m={item.comment_id} />
                </div>
              ))}
            </ul>
          )}
        </div>
      </center>
    </div>
  );
}
