
import React, { useState, useEffect } from "react";
import "../Styles/Show.css";
import config from "../../config/Config";
import { Link } from "react-router-dom";

export default function Tags({ hideSidebar }) {
  const [data, setData] = useState([]);
  const [showTitles, setShowTitles] = useState(false);

  function demo() {
    fetch(`${config.API_BLOG}tag`).then((result) => {
      result.json().then((res) => {
        setData(res);
      });
    });
  }

  useEffect(() => {
    demo();
  }, []);

  const toggleTitles = () => {
    setShowTitles(!showTitles);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <span
          style={{ color: "white", fontSize: "20px", cursor: "pointer" }}
          onClick={toggleTitles}
        >
          <b className="ms-3">Tags</b>
        </span>
        {showTitles && (
          <div>
            {data
              .filter((item) => item.posts_count > 0)
              .map((item) => (
                <div key={item.id} className="card-body fs-6 ms-3">
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to={`/blog/tag/${item.tag_id}`}
                    state={{ item }}
                    onClick={hideSidebar} 
                  >
                    {item.tag_name} ({item.posts_count})
                  </Link>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
