// import React, { useState, useEffect } from "react";
// import "../Styles/Show.css";
// import config from "../../config/Config";
// import { useParams } from "react-router-dom";
// import LessonSidebar from "./LessonSidebar";
// import { Helmet } from "react-helmet";

// export default function Tutorial() {
//   const [data, setData] = useState([]);
//   const [configuration, setConfiguration] = useState([]);
//   const { id } = useParams();

//   function demo() {
//     fetch(`${config.API_TUTORIAL}lession`).then((result) => {
//       result.json().then((res) => {
//         setData(res);
//       });
//     });
//   }

//   function fetchConfiguration() {
//     fetch(`${config.API_BLOG}configuration`)
//       .then((result) => result.json())
//       .then((res) => {
//         setConfiguration(res);
//       })
//       .catch((err) => console.error("Error fetching configuration:", err));
//   }

//   useEffect(() => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//     demo();
//     fetchConfiguration();
//   }, [id]);

//   const renderHTML = (rawHTML) => {
//     // You can use a custom regex or logic to handle images here if necessary
//     return { __html: rawHTML };
//   };

//   const metaTitle = configuration[0]?.default_meta_title;
//   const metaKeywords = configuration[0]?.default_meta_keywords;
//   const metaDescription = configuration[0]?.default_meta_description;

//   let filteredData = data.filter((item) => item.lesson_id == id);

//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div className="col-sm-1"></div>
//         <div className="col-sm-10 mb-5">
//           <div className="row font_family">
//             {filteredData.map((item) => {
//               return (
//                 <div class="card mt-5 p-2" key={item.id}>
//                   {/* *********************** */}

//                   <Helmet>
//                     <title>{item.lesson_title}</title>
//                     <meta
//                       name="meta_title"
//                       content={`${item.meta_title || metaTitle}`}
//                     />
//                     <meta
//                       name="meta_keyword"
//                       content={`${item.meta_keyword || metaKeywords}`}
//                     />
//                     <meta
//                       name="meta_description"
//                       content={`${item.meta_description || metaDescription}`}
//                     />
//                   </Helmet>

//                   {/* ************************* */}
//                   <div
//                     className="card-header text-center"
//                     style={{ background: "#173a9b" }}
//                   >
//                     <h3 className="card-title text-light">
//                       {item.lesson_title}
//                     </h3>
//                   </div>

//                   {/* Render lesson content including images */}
//                   <div
//                     dangerouslySetInnerHTML={renderHTML(item.lesson_content)}
//                   />
//                 </div>
//               );
//             })}
//           </div>
//           <div className="side2">
//             <LessonSidebar />
//           </div>
//         </div>
//         <div className="col-sm-1"></div>
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import "../Styles/Show.css";
import config from "../../config/Config";
import { useParams } from "react-router-dom";
import LessonSidebar from "./LessonSidebar";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import parse from "html-react-parser";
import { decode } from "html-entities";
export default function Tutorial() {
  const [data, setData] = useState([]);
  const [configuration, setConfiguration] = useState([]);
  const { id } = useParams();

  function demo() {
    fetch(`${config.API_TUTORIAL}lession`).then((result) => {
      result.json().then((res) => {
        setData(res);
      });
    });
  }

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    demo();
    fetchConfiguration();
  }, [id]);

  function renderDescription(description) {
    const preTagRegex = /<pre[^>]*>(?:<code[^>]*>)?(.*?)(?:<\/code>)?<\/pre>/gs;
    let parts = [];
    let lastIndex = 0;
    let match;

    while ((match = preTagRegex.exec(description)) !== null) {
      if (match.index > lastIndex) {
        parts.push(parse(description.slice(lastIndex, match.index)));
      }
      let decodedCode = decode(match[1].trim());
      decodedCode = decodedCode.replace(/<br\s*\/?>/g, "");
      decodedCode = decodedCode.replace(/<\/?strong>/g, "");

      const codeBlock = (
        <div
          className="code-container"
          key={match.index}
          style={{ position: "relative" }}
        >
          <SyntaxHighlighter language="javascript" style={oneDark}>
            {decodedCode}
          </SyntaxHighlighter>
          <div className="code-buttons p-1">
            <button onClick={() => copyCode(decodedCode)}>Copy</button>
            <button onClick={(event) => openRow(decodedCode, event)}>
              Simple Text
            </button>
            <button onClick={() => openExternal(decodedCode)}>External</button>
          </div>
        </div>
      );

      parts.push(codeBlock);
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < description.length) {
      parts.push(parse(description.slice(lastIndex)));
    }

    return parts;
  }

  function copyCode(code) {
    navigator.clipboard.writeText(code).then(() => {
      toast.success("Code copied to clipboard!");
    });
  }

  function openExternal(code) {
    const win = window.open("", "_blank", "width=800,height=600,resizable=yes");
    win.document.write(`<pre>${code}</pre>`);
    win.document.close();
  }

  function openRow(code, event) {
    const button = event.target;
    const container = button.closest(".code-container");
    const preTag = container.querySelector("pre");

    if (!preTag) return;
    if (preTag.dataset.original) {
      preTag.innerHTML = preTag.dataset.original;
      preTag.removeAttribute("data-original");
    } else {
      preTag.dataset.original = preTag.innerHTML;
      preTag.innerHTML = decode(code);
    }
  }

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  let filteredData = data.filter((item) => item.lesson_id == id);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10 mb-5">
          <div className="row font_family">
            {filteredData.map((item) => {
              return (
                <div class="card mt-5 p-2" key={item.id}>
                  {/* *********************** */}

                  <Helmet>
                    <title>{item.lesson_title}</title>
                    <meta
                      name="meta_title"
                      content={`${item.meta_title || metaTitle}`}
                    />
                    <meta
                      name="meta_keyword"
                      content={`${item.meta_keyword || metaKeywords}`}
                    />
                    <meta
                      name="meta_description"
                      content={`${item.meta_description || metaDescription}`}
                    />
                  </Helmet>

                  {/* ************************* */}
                  <div
                    className="card-header text-center"
                    style={{ background: "#173a9b" }}
                  >
                    <h3 className="card-title text-light">
                      {item.lesson_title}
                    </h3>
                  </div>
                  <div style={{ fontFamily: "sofia" }}>
                    {renderDescription(
                      item?.lesson_content || "<p>No description available</p>"
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="side2">
            <LessonSidebar />
          </div>
        </div>
        <div className="col-sm-1"></div>
      </div>
      <ToastContainer />
    </div>
  );
}
