// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import { Link } from "react-router-dom";
// import * as AiIcons from "react-icons/ai";
// import SidebarData from "./SidebarData";

// import { IconContext } from "react-icons/lib";

// const Nav = styled.div`
//   background: #173a9b;
//   height: 250px;
//   width: 30px;
//   position: fixed;
// `;

// const NavIcon = styled(Link)`
//   margin-right: 2rem;
//   font-size: 2rem;
// `;

// const SidebarNav = styled.nav`
//   background: #173a9b;
//   width: 250px;
//   min-height: 330px;
//   margin-top: 340px;
//   display: flex;
//   justify-content: center;
//   position: fixed;
//   top: 0;
//   right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
//   transition: 350ms;
//   z-index: 10;
//   cursor: pointer;
// `;

// const SidebarWrap = styled.div`
//   width: 100%;
// `;

// const Sidebar = () => {
//   const [sidebar, setSidebar] = useState(false);

//   const showSidebar = () => setSidebar(!sidebar);

//   return (
//     <>
//       <IconContext.Provider value={{ color: "#fff" }}>
//         <Nav onClick={showSidebar}>
//           <span className="text-light ms-2">S</span>
//           <br />
//           <span className="text-light ms-2">I</span>
//           <br />
//           <span className="text-light ms-2">D</span>
//           <br />
//           <span className="text-light ms-2">E</span>
//           <br />
//           <span className="text-light ms-2">B</span>
//           <br />
//           <span className="text-light ms-2">A</span>
//           <br />
//           <span className="text-light ms-2">R</span>
//           <br />
//         </Nav>
//         <SidebarNav sidebar={sidebar}>
//           <SidebarWrap>
//             <NavIcon to="#">
//               <AiIcons.AiOutlineClose onClick={showSidebar} />
//             </NavIcon>
//             <SidebarData />
//           </SidebarWrap>
//         </SidebarNav>
//       </IconContext.Provider>
//     </>
//   );
// };
// export default Sidebar;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import SidebarData from "./SidebarData";

import { IconContext } from "react-icons/lib";

const Nav = styled.div`
  background: #173a9b;
  height: 250px;
  width: 30px;
  position: fixed;
`;

const NavIcon = styled(Link)`
  margin-right: 2rem;
  font-size: 2rem;
`;

const SidebarNav = styled.nav`
  background: #173a9b;
  width: 250px;
  min-height: 330px;
  margin-top: 340px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  cursor: pointer;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const hideSidebar = () => setSidebar(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav onClick={showSidebar}>
          <span className="text-light ms-2 sidebar_span">S</span>
          <br />
          <span className="text-light ms-2 sidebar_span">I</span>
          <br />
          <span className="text-light ms-2 sidebar_span">D</span>
          <br />
          <span className="text-light ms-2 sidebar_span">E</span>
          <br />
          <span className="text-light ms-2 sidebar_span">B</span>
          <br />
          <span className="text-light ms-2 sidebar_span">A</span>
          <br />
          <span className="text-light ms-2 sidebar_span">R</span>
          <br />
        </Nav>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            <SidebarData hideSidebar={hideSidebar} />
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
