import React, { useState, useEffect } from "react";
import "../Styles/Home.css";
import { dataFormatter } from "../../constant/condition";
import { Link } from "react-router-dom";
import config from "../../config/Config";
import { Helmet } from "react-helmet";
import "react-country-state-city/dist/react-country-state-city.css";

export default function Home() {
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [configuration, setConfiguration] = useState([]);
  function fetchData() {
    fetch(`${config.API_BLOG}getdata`)
      .then((result) => {
        result.json().then((res) => {
          setData(res);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function fetchSeriesData() {
    fetch(`${config.API_TUTORIAL}getseries`)
      .then((result) => {
        result.json().then((res) => {
          setDatas(res);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    fetchData();
    fetchSeriesData();
    fetchConfiguration();
  }, []);

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  const renderHTML = (rawHTML) => {
    return { __html: rawHTML };
  };

  return (
    <div className="container-fluid mt-3 mb-4">
      {/* ***************************************** */}

      <Helmet>
        <title>ilearnings</title>
        <meta name="default_meta_title" content={metaTitle} />
        <meta name="default_meta_keywords" content={metaKeywords} />
        <meta name="default_meta_description" content={metaDescription} />
      </Helmet>

      {/* ************************************************ */}
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <center
            style={{
              fontSize: "38px",
              fontFamily: "sofia",
            }}
          >
            <b>
              Latest Blog <b style={{ color: "#173a9b" }}>Post</b>
            </b>
            <hr className="w-25 mx-auto" />
          </center>
          <div className="row">
            {data.map((item) => {
              let newTypeDate = dataFormatter(item.created_at);
              {
                /* const limitedContent = item.post_content
                .split(" ")
                .slice(0, 40)
                .join(" "); */
              }

              const limitedContent = (() => {
                const parser = new DOMParser();
                const plainText =
                  parser.parseFromString(item.post_content, "text/html").body
                    .textContent || "";
                return (
                  plainText.split(/\s+/).slice(0, 40).join(" ") +
                  (plainText.split(/\s+/).length > 40 ? "..." : "")
                );
              })();

              return (
                <div
                  className="col-md-12 col-lg-12 col-xl-6  mt-3"
                  key={item.post_id}
                >
                  <div className="card mt-2 p-3 font_family">
                    <span state={{ item }} className="blogs text-dark">
                      <b>{item.post_title}</b>
                    </span>
                    <div className="row">
                      <div className="col-sm-3  mt-2">
                        <center className="p-2">
                          <img
                            // className="w-100 img-fluid "
                            src={item.post_banner_b64}
                            style={{ height: "100px", width: "150px" }}
                            className="img-fluid"
                            alt={item.post_banner_b64}
                          />
                        </center>
                      </div>
                      <div className="col-sm-9 mt-2">
                        <span
                          style={{
                            color: "black",
                            fontFamily: "sofia",
                            textAlign: "justify",
                          }}
                          dangerouslySetInnerHTML={renderHTML(limitedContent)}
                        ></span>

                        <div className="row">
                          <Link
                            to={`/read/${item.post_id}`}
                            state={{ item }}
                            style={{
                              height: "20px",
                              lineHeight: "8px",
                              fontSize: "15px",
                              textDecoration: "none",
                            }}
                          >
                            <b style={{ float: "right", fontFamily: "sofia" }}>
                              >Read More
                            </b>
                          </Link>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-2">
                        <center>
                          <div
                            className="card-header"
                            style={{
                              minHeight: "35px",
                              lineHeight: "20px",
                              background: "#173a9b",
                            }}
                          >
                            <div className="row text-center text-light font_family">
                              <div className="col-sm-4">
                                <p
                                  className="card-text"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <b> By:</b> {item.name}
                                </p>
                              </div>
                              <div className="col-sm-4">
                                <p className="card-text ">
                                  <b> On:</b> {newTypeDate}
                                </p>
                              </div>
                              <div className="col-sm-4">
                                <p
                                  className="card-text text-light"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <b>Tags:</b> {item.tags}
                                </p>
                              </div>
                            </div>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-sm-1"></div>
      </div>

      <div className="row mt-4">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <center style={{ fontSize: "38px", fontFamily: "sofia" }}>
            <b>
              Tutorial<b style={{ color: "#173a9b" }}> Series</b>
            </b>
            <hr className="w-25 mx-auto" />
          </center>
          <div className="row">
            {datas.map((item) => {
              {
                /* const limitedContentTutorials = item.tutorial_series_description
                .split(" ")
                .slice(0, 40)
                .join(" "); */
              }

              const limitedContentTutorials = (() => {
                const parser = new DOMParser();
                const plainText =
                  parser.parseFromString(
                    item.tutorial_series_description,
                    "text/html"
                  ).body.textContent || "";
                return (
                  plainText.split(/\s+/).slice(0, 40).join(" ") +
                  (plainText.split(/\s+/).length > 40 ? "..." : "")
                );
              })();

              return (
                <div
                  className="col-md-12 col-lg-12 col-xl-6 mt-3"
                  key={item.id}
                >
                  <div className="card mt-2 p-3 font_family">
                    <Link
                      to={`/tutorial/${item.tutorial_series_name}`}
                      state={{ item }}
                      className="tutorial"
                    >
                      <b>{item.tutorial_series_name}</b>
                    </Link>
                    <div className="row">
                      <div className="col-sm-3">
                        <center className="p-2">
                          <img
                            src={item.tutorial_series_image_b64}
                            style={{ height: "100px", width: "150px" }}
                            alt={item.tutorial_series_image_b64}
                            className="img-fluid"
                          />
                        </center>
                      </div>
                      <div className="col-sm-9 mt-2">
                        <span
                          dangerouslySetInnerHTML={renderHTML(
                            limitedContentTutorials
                          )}
                        ></span>

                        <div className="row">
                          <Link
                            to={`/tutorial/${item.tutorial_series_name}`}
                            state={{ item }}
                            style={{
                              height: "20px",
                              lineHeight: "8px",
                              fontSize: "15px",
                              textDecoration: "none",
                            }}
                          >
                            <b style={{ float: "right" }}>&gt; Show More</b>
                          </Link>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-2">
                        <center>
                          <div
                            className="card-header"
                            style={{
                              minHeight: "35px",
                              lineHeight: "20px",
                              background: "#173a9b",
                            }}
                          >
                            <div className="row text-light">
                              <div className="col-sm-6">
                                <p
                                  className="card-text"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <b>#Lesson:</b> {item.lessons_count}
                                </p>
                              </div>
                              <div className="col-sm-6">
                                <p
                                  className="card-text text-light"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <b>Technology:</b> {item.technologies}
                                </p>
                              </div>
                            </div>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-sm-1"></div>
      </div>
    </div>
  );
}
