import React, { useState, useEffect } from "react";
import "../Styles/Show.css";
import { useParams } from "react-router-dom";
import config from "../../config/Config";
import { Link } from "react-router-dom";
export default function Lessondata() {
  const [data, setData] = useState([]);
  const [showTitles, setShowTitles] = useState(false);
  const { id } = useParams();
  function demo() {
    fetch(`${config.API_TUTORIAL}tutorials?key=${id}`).then((result) => {
      result.json().then((res) => {
        setData(res);
        console.log(id);
      });
    });
  }

  useEffect(() => {
    demo();
  }, []);

  const toggleTitles = () => {
    setShowTitles(!showTitles);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <span
          style={{ color: "white", fontSize: "20px", cursor: "pointer" }}
          onClick={toggleTitles}
        >
          <b className="ms-1">Lesson</b>
        </span>

        <div>
          {data.map((item) => (
            <div key={item.id}>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={`/less/${item.lesson_id}`}
                state={{ item }}
              >
                {item.lesson_title}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
