import React, { useState } from "react";
import "../Styles/Search.css";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import config from "../../config/Config";
const Search = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await fetch(`${config.API_BLOG}series/${searchTerm}`);
      if (!response.ok) throw new Error("Failed to fetch data");
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchTerm.trim() === "") {
      alert("Please enter a search term");
      return;
    }
    const fetchedData = await fetchData();
    console.log(fetchedData);
    navigate("/search", { state: { data: fetchedData } });
    setSearchTerm("");
  };

  // Handle "Enter" key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch(e);
    }
  };

  return (
    <div>
      <div className="search">
        <input
          className="search-txt"
          type="text"
          name="search"
          placeholder="Search......?"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button type="button" className="search-btn" onClick={handleSearch}>
          <FaSearch />
        </button>
      </div>
    </div>
  );
};

export default Search;
