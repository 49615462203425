import React, { useState, useEffect } from "react";
import "../Styles/Show.css";
import config from "../../config/Config";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Blogcategory({ hideSidebar }) {
  const [data, setData] = useState([]);
  const [showTitles, setShowTitles] = useState(false);

  function demo() {
    fetch(`${config.API_BLOG}get_category`).then((result) => {
      result.json().then((res) => {
        setData(res);
      });
    });
  }

  useEffect(() => {
    demo();
  }, []);

  const toggleTitles = () => {
    setShowTitles(!showTitles);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <span
          style={{ color: "white", fontSize: "20px", cursor: "pointer" }}
          onClick={toggleTitles}
        >
          <b className="ms-3">Blog Category</b>
        </span>
        {showTitles && (
          <div>
            {data
              .filter((item) => item.posts_count > 0) // Filter categories with non-zero posts count
              .map((item) => (
                <div key={item.category_id} className="card-body ms-3 fs-6">
                  <Helmet>
                    <meta name="meta_title" content={`${item.title}`} />
                    <meta
                      name="meta_description"
                      content={`${item.meta_description}`}
                    />
                    <meta
                      name="meta_keywords"
                      content={`${item.meta_keywords}`}
                    />
                  </Helmet>
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to={`/blog/category/${item.category_id}`}
                    state={{ item }}
                    onClick={hideSidebar}
                  >
                    {item.category_name} ({item.posts_count})
                  </Link>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
