
import React from "react";
import Archives from "./Archives";
import Tags from "./Tags";
import Blogcategory from "./Blogcategory";

export default function SidebarData({ hideSidebar }) {
  return (
    <div className="row mt-2">
      <Blogcategory hideSidebar={hideSidebar} />
      <Archives />
      <Tags hideSidebar={hideSidebar} />
    </div>
  );
}
