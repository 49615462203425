import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { dataFormatter } from "../../constant/condition";
import "../Styles/Home.css";
import NotFound from "./NotFound";
import config from "../../config/Config";
import { Helmet } from "react-helmet";

export default function SearchPage() {
  const location = useLocation();
  const [configuration, setConfiguration] = useState([]);
  const { data } = location.state || {};
  const [activeTab, setActiveTab] = useState("blog");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    fetchConfiguration();
  });

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  const renderHTML = (rawHTML) => {
    return { __html: rawHTML };
  };

  const hasBlogPosts = data?.blog_posts?.length > 0;
  const hasTutorialSeries = data?.tutorial_series?.length > 0;

  return (
    <div className="mb-4">
      {hasBlogPosts || hasTutorialSeries ? (
        <div>
          <center className="mt-2">
            <button
              className={`btn btn-primary ${
                activeTab === "blog" ? "active" : ""
              } m-2`}
              onClick={() => handleTabChange("blog")}
            >
              <b style={{ fontFamily: "sofia" }}>
                Blog ({data?.blog_posts?.length || 0})
              </b>
            </button>
            <button
              className={`btn btn-primary ${
                activeTab === "tutorial" ? "active" : ""
              }`}
              onClick={() => handleTabChange("tutorial")}
            >
              <b style={{ fontFamily: "sofia" }}>
                Tutorial ({data?.tutorial_series?.length || 0})
              </b>
            </button>
          </center>

          <hr className="w-25 mx-auto" />
          <div className="row">
            {activeTab === "blog" && hasBlogPosts ? (
              <div>
                {/****************************************** / Blog section *********************************************************************/}
                <div className="row">
                  <div className="col-sm-1"></div>
                  <div className="col-sm-10">
                    <Helmet>
                      <meta name="default_meta_title" content={metaTitle} />
                      <meta
                        name="default_meta_keywords"
                        content={metaKeywords}
                      />
                      <meta
                        name="default_meta_description"
                        content={metaDescription}
                      />
                    </Helmet>
                    <div className="row">
                      {data.blog_posts.map((blog) => {
                        const newTypeDate = dataFormatter(blog.created_at);
                        const limitedContent = blog.post_content
                          .split(" ")
                          .slice(0, 40)
                          .join(" ");
                        return (
                          <div
                            className="col-sm-6 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-3"
                            key={blog.post_id}
                          >
                            <div className="card mt-2 p-3 font_family">
                              <span className="blogs text-dark">
                                <b>{blog.post_title}</b>
                              </span>
                              <div className="row">
                                <div className="col-sm-3 mt-2">
                                  <center className="p-2">
                                    <img
                                      src={blog.post_banner_b64}
                                      style={{
                                        height: "150px",
                                        width: "100%",
                                      }}
                                      alt="Blog banner"
                                    />
                                  </center>
                                </div>
                                <div className="col-sm-9 mt-2">
                                  <span
                                    style={{
                                      color: "black",
                                      fontFamily: "sofia",
                                      textAlign: "justify",
                                    }}
                                    dangerouslySetInnerHTML={renderHTML(
                                      limitedContent
                                    )}
                                  ></span>
                                  <div className="row">
                                    <Link
                                      to={`/read/${blog.post_id}`}
                                      state={{ blog }}
                                      style={{
                                        height: "20px",
                                        lineHeight: "8px",
                                        fontSize: "15px",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <b
                                        style={{
                                          float: "right",
                                          fontFamily: "sofia",
                                        }}
                                      >
                                        >Read More
                                      </b>
                                    </Link>
                                  </div>
                                </div>
                                <div className="col-sm-12 mt-2">
                                  <center>
                                    <div
                                      className="card-header"
                                      style={{
                                        minHeight: "35px",
                                        lineHeight: "20px",
                                        background: "#173a9b",
                                      }}
                                    >
                                      <div className="row text-center text-light">
                                        <div className="col-sm-4">
                                          <p
                                            className="card-text"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <b> By:</b> {blog.name}
                                          </p>
                                        </div>
                                        <div className="col-sm-4">
                                          <p className="card-text ">
                                            <b> On:</b> {newTypeDate}
                                          </p>
                                        </div>
                                        <div className="col-sm-4">
                                          <p
                                            className="card-text text-light"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <b>Tags:</b> {blog.tags}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </center>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
              </div>
            ) : activeTab === "blog" ? (
              <center style={{ fontFamily: "sofia", fontSize: "30px" }}>
                <b>No blog posts found</b>
              </center>
            ) : null}

            {activeTab === "tutorial" && hasTutorialSeries ? (
              <div>
                {/****************************************** / Tutorial section *********************************************************************/}

                <div className="row ">
                  <div className="col-sm-1"></div>
                  <div className="col-sm-10">
                    <div className="row">
                      {data.tutorial_series.map((tutorial) => {
                        const limitedContentTutorials =
                          tutorial.tutorial_series_description
                            .split(" ")
                            .slice(0, 30)
                            .join(" ");

                        return (
                          <div
                            className="col-sm-6 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-3"
                            key={tutorial.id}
                          >
                            <div className="card mt-2 p-3 font_family">
                              <Link
                                to={`/tutorial/${tutorial.tutorial_series_name}`}
                                state={{ tutorial }}
                                className="tutorial"
                              >
                                <b>{tutorial.tutorial_series_name}</b>
                              </Link>
                              <div className="row">
                                <div className="col-sm-3">
                                  <center className="p-2">
                                    <img
                                      src={tutorial.tutorial_series_image_b64}
                                      style={{ height: "150px", width: "100%" }}
                                      alt={tutorial.tutorial_series_image_b64}
                                    />
                                  </center>
                                </div>
                                <div className="col-sm-9 mt-2">
                                  <span
                                    dangerouslySetInnerHTML={renderHTML(
                                      limitedContentTutorials
                                    )}
                                  ></span>

                                  <div className="row">
                                    <Link
                                      to={`/tutorial/${tutorial.tutorial_series_name}`}
                                      state={{ tutorial }}
                                      style={{
                                        height: "20px",
                                        lineHeight: "8px",
                                        fontSize: "15px",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <b style={{ float: "right" }}>
                                        &gt; Show More
                                      </b>
                                    </Link>
                                  </div>
                                </div>
                                <div className="col-sm-12 mt-2">
                                  <center>
                                    <div
                                      className="card-header"
                                      style={{
                                        minHeight: "35px",
                                        lineHeight: "20px",
                                        background: "#173a9b",
                                      }}
                                    >
                                      <div className="row text-light">
                                        <div className="col-sm-6">
                                          <p
                                            className="card-text"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <b>#Lesson:</b>{" "}
                                            {tutorial.lessons_count}
                                          </p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p
                                            className="card-text text-light"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <b>Technology:</b>{" "}
                                            {tutorial.technologies}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </center>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
              </div>
            ) : activeTab === "tutorial" ? (
              <center style={{ fontFamily: "sofia", fontSize: "30px" }}>
                <b>No tutorials found</b>
              </center>
            ) : null}
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
}
