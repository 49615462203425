import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import "../Styles/Readmore.css";
import Comment from "./Comment";
import config from "../../config/Config";
import { dataFormatter } from "../../constant/condition";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import parse from "html-react-parser";
import { decode } from "html-entities";
import { Helmet } from "react-helmet";
export default function Read() {
  const [configuration, setConfiguration] = useState([]);
  const [data, setData] = useState([]);
  const [comment_author_name, setComment_author_name] = useState("");
  const [comment_author_email, setComment_author_email] = useState("");
  const [comment_content, setComment_content] = useState("");
  const [comment_post_id, setComment_post_id] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const { id } = useParams(); 

  console.log(id);
  const emailValidation = () => {
    return String(comment_author_email)
      .toLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  function handal() {
    fetch(`${config.API_BLOG}comment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        comment_author_name,
        comment_author_email,
        comment_content,
        comment_post_id,
      }),
    }).then((result) => {
      result.json().then((res) => {
        toast.success(res.message || "Comment submitted successfully!");
      });
    });
  }
  const handleSend = (e) => {
    e.preventDefault();
    if (comment_content === "") {
      setErrMsg("Message is required!");
    } else if (comment_author_name === "") {
      setErrMsg("Username  is required!");
    } else if (comment_author_email === "") {
      setErrMsg("Please provide your Email!");
    } else if (!emailValidation(comment_author_email)) {
      setErrMsg("Please provide a valid Email!");
    } else {
      setComment_author_name("");
      setComment_author_email("");
      setComment_content("");
      setComment_post_id("");

      handal();
    }
  };

  function renderDescription(description) {
    const preTagRegex = /<pre[^>]*>(?:<code[^>]*>)?(.*?)(?:<\/code>)?<\/pre>/gs;
    let parts = [];
    let lastIndex = 0;
    let match;

    while ((match = preTagRegex.exec(description)) !== null) {
      if (match.index > lastIndex) {
        parts.push(parse(description.slice(lastIndex, match.index)));
      }
      let decodedCode = decode(match[1].trim());
      decodedCode = decodedCode.replace(/<br\s*\/?>/g, "");
      decodedCode = decodedCode.replace(/<\/?strong>/g, "");

      const codeBlock = (
        <div
          className="code-container"
          key={match.index}
          style={{ position: "relative" }}
        >
          <SyntaxHighlighter language="javascript" style={oneDark}>
            {decodedCode}
          </SyntaxHighlighter>
          <div className="code-buttons p-1">
            <button onClick={() => copyCode(decodedCode)}>Copy</button>
            <button onClick={(event) => openRow(decodedCode, event)}>
              Simple Text
            </button>
            <button onClick={() => openExternal(decodedCode)}>External</button>
          </div>
        </div>
      );

      parts.push(codeBlock);
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < description.length) {
      parts.push(parse(description.slice(lastIndex)));
    }

    return parts;
  }

  function copyCode(code) {
    navigator.clipboard.writeText(code).then(() => {
      toast.success("Code copied to clipboard!");
    });
  }

  function openExternal(code) {
    const win = window.open("", "_blank", "width=800,height=600,resizable=yes");
    win.document.write(`<pre>${code}</pre>`);
    win.document.close();
  }

  function openRow(code, event) {
    const button = event.target;
    const container = button.closest(".code-container");
    const preTag = container.querySelector("pre");

    if (!preTag) return;
    if (preTag.dataset.original) {
      preTag.innerHTML = preTag.dataset.original;
      preTag.removeAttribute("data-original");
    } else {
      preTag.dataset.original = preTag.innerHTML;
      preTag.innerHTML = decode(code);
    }
  }

  function demo() {
    fetch(`${config.API_BLOG}readmore`).then((result) => {
      result.json().then((res) => {
        setData(res);
      });
    });
  }

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    setComment_post_id(id);
    demo();
    fetchConfiguration();
  }, []);

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  const renderHTML = (rawHTML) => {
    return { __html: rawHTML };
  };

  window.localStorage.setItem("st", JSON.stringify(id));

  let filteredData = data.filter((item) => item.post_id == id);

  const manish = () => {
    const From = document.querySelector("#read1");
    const From1 = document.querySelector("#read");

    From.style.display = "block";
    From1.style.display = "none";
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          {filteredData.map((item) => {
            let newTypeDate = dataFormatter(item.created_at);

            return (
              <div className="row" key={item.id}>
                <Helmet>
                  <title>{item.post_title}</title>
                  <meta
                    name="meta_title"
                    content={`${item.post_meta_title || metaTitle}`}
                  />

                  <meta
                    name="meta_keywords"
                    content={`${item.post_meta_keywords || metaKeywords}`}
                  />

                  <meta
                    name="meta_description"
                    content={`${item.post_meta_description || metaDescription}`}
                  />
                </Helmet>

                <div className="col-sm-12 card mt-5 mb-5">
                  <div className="row">
                    <div className="col-sm-12 font_family">
                      <center>
                        <div class="background_Color mt-2 text-light p-2">
                          <h3 className="card-title">{item.post_title}</h3>
                        </div>
                      </center>
                      {/* <div
                        style={{
                          color: "black",
                          fontFamily: "sofia",
                          textAlign: "justify",
                        }}
                        dangerouslySetInnerHTML={renderHTML(item.post_content)}
                      /> */}
                      <div style={{ fontFamily: "sofia" }}>
                        {renderDescription(
                          item?.post_content ||
                            "<p>No description available</p>"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 background_Color font_family">
                    <center>
                      <div class="card-header" style={{ minHeight: "40px" }}>
                        <div className="row text-center text-light ">
                          <div className="col-sm-4">
                            <p
                              className="card-text"
                              style={{ textTransform: "capitalize" }}
                            >
                              <b> By:</b> {item.name}
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <p className="card-text ">
                              <b> On:</b> {newTypeDate}
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <p
                              className="card-text"
                              style={{ textTransform: "capitalize" }}
                            >
                              <b>Tags:</b> {item.tags}
                            </p>
                          </div>
                        </div>
                      </div>
                    </center>
                  </div>

                  <hr />
                  <div className="row mt-4">
                    <div className="col-sm-4">
                      <Comment />
                    </div>
                    <div className="col-sm-8">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-2">
                              <center
                                style={{ fontSize: "50px", color: "#173a9b" }}
                              >
                                <FaUserCircle />
                              </center>
                            </div>

                            <div className="col-sm-6 col-md-10 col-lg-6">
                              <div onClick={manish} id="read">
                                <span style={{ fontSize: "20px" }}>
                                  Be the First to Comment!.....
                                </span>
                              </div>

                              <form>
                                <div id="read1">
                                  <div className="row mb-3">
                                    {errMsg && (
                                      <p style={{ color: "red" }}>{errMsg}</p>
                                    )}
                                    {successMsg && (
                                      <p className="py-3 bg-gradient text-dark text-center fs-5">
                                        {successMsg}
                                      </p>
                                    )}
                                    <div className="mb-3 text-dark">
                                      {/* <label htmlFor="comment">Comment</label>  */}
                                      <textarea
                                        onChange={(e) =>
                                          setComment_content(e.target.value)
                                        }
                                        value={comment_content}
                                        className={`form-control ${
                                          errMsg === "Comment is required!" &&
                                          "is-invalid"
                                        }`}
                                        id="comment"
                                        placeholder="Be the First to Comment!....."
                                      ></textarea>
                                    </div>
                                    <div className="mb-3 text-dark">
                                      {/* <label htmlFor="name">Your name</label>  */}
                                      <input
                                        onChange={(e) =>
                                          setComment_author_name(e.target.value)
                                        }
                                        value={comment_author_name}
                                        className={` form-control ss${
                                          errMsg === "name is required!" &&
                                          "is-invalid"
                                        }`}
                                        type="text"
                                        id="name"
                                        placeholder="Name *"
                                      />
                                    </div>
                                    <div className="mb-3 text-dark">
                                      {/* <label htmlFor="email">Email</label>  */}
                                      <input
                                        onChange={(e) =>
                                          setComment_author_email(
                                            e.target.value
                                          )
                                        }
                                        value={comment_author_email}
                                        className={`form-control ${
                                          errMsg ===
                                            "Please give your Email!" &&
                                          "is-invalid"
                                        }`}
                                        type="email"
                                        id="email"
                                        placeholder="Email *"
                                      />
                                    </div>

                                    <div className="mb-3 text-dark">
                                      <input
                                        hidden
                                        value={id}
                                        className={`form-control ${
                                          errMsg ===
                                            "contact number is required!" &&
                                          "is-invalid"
                                        }`}
                                        type="text"
                                        id="contact"
                                        placeholder=" Contact No*"
                                      />
                                    </div>

                                    <div className=" text-light">
                                      <button
                                        onClick={handleSend}
                                        className="background_Color p-2 text-light  "
                                      >
                                        Send Message
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-sm-1"></div>
      </div>
      <ToastContainer />
    </div>
  );
}
