import React, { useState, useEffect } from "react";
import "../Styles/Show.css";
import { Link } from "react-router-dom";
import config from "../../config/Config";
function getMonthName(month) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[month - 1];
}

export default function Archives() {
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [showTitles, setShowTitles] = useState(false);

  function fetchData() {
    fetch(`${config.API_BLOG}getdata`).then((result) => {
      result.json().then((res) => {
        setData(res);
      });
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleYearClick = (year) => {
    if (selectedYear === year) {
      setSelectedYear(null);
      setSelectedMonth(null);
    } else {
      setSelectedYear(year);
      setSelectedMonth(null);
    }
  };

  const countMonthsInYear = (year) => {
    return data
      .filter((item) => item.published_date.startsWith(year))
      .reduce((acc, item) => {
        const month = item.published_date.slice(5, 7);
        acc[month] = (acc[month] || 0) + 1;
        return acc;
      }, {});
  };

  return (
    <div className="container-fluid">
      <span
        style={{ fontSize: "20px" }}
        onClick={() => setShowTitles(!showTitles)}
      >
        <b style={{ color: "white" }} className="ms-3">
          Archives
        </b>
      </span>
      <div className="row ms-3">
        <div className="col-sm-1" style={{ color: "white" }}>
          {showTitles &&
            Array.from(
              new Set(data.map((item) => item.published_date.slice(0, 4)))
            ).map((year) => {
              const monthsForYear = countMonthsInYear(year);
              return (
                <div
                  key={year}
                  className={`list-group-item ${
                    selectedYear === year ? "active" : ""
                  }`}
                >
                  <span onClick={() => handleYearClick(year)}>{year}</span>
                  {selectedYear === year ? (
                    <div>
                      {Object.keys(monthsForYear).map((month) => (
                        <div key={month}>
                          <Link
                            to={`/blog/year/${year}/month/${month}`}
                            style={{
                              textDecoration: "none",
                              fontSize: "15px",
                              color: "white",
                            }}
                          >
                            {getMonthName(month)}({monthsForYear[month]})
                          </Link>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
