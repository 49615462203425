import React from 'react'
 import Lessondata from './Lessondata';

export default function LessonSidedata() {
  return (
    <div>
     <Lessondata/>
    </div>
  )
}
