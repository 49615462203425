import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import config from "../../config/Config";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import parse from "html-react-parser";
import { decode } from "html-entities";
export default function Tutorial() {
  const [data, setData] = useState([]);
  const [configuration, setConfiguration] = useState([]);
  const { id } = useParams();

  function fetchData() {
    fetch(`${config.API_TUTORIAL}tutorial`)
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  function renderDescription(description) {
    const preTagRegex = /<pre[^>]*>(?:<code[^>]*>)?(.*?)(?:<\/code>)?<\/pre>/gs;
    let parts = [];
    let lastIndex = 0;
    let match;

    while ((match = preTagRegex.exec(description)) !== null) {
      if (match.index > lastIndex) {
        parts.push(parse(description.slice(lastIndex, match.index)));
      }
      let decodedCode = decode(match[1].trim());
      decodedCode = decodedCode.replace(/<br\s*\/?>/g, "");
      decodedCode = decodedCode.replace(/<\/?strong>/g, "");

      const codeBlock = (
        <div
          className="code-container"
          key={match.index}
          style={{ position: "relative" }}
        >
          <SyntaxHighlighter language="javascript" style={oneDark}>
            {decodedCode}
          </SyntaxHighlighter>
          <div className="code-buttons p-1">
            <button onClick={() => copyCode(decodedCode)}>Copy</button>
            <button onClick={(event) => openRow(decodedCode, event)}>
              Simple Text
            </button>
            <button onClick={() => openExternal(decodedCode)}>External</button>
          </div>
        </div>
      );

      parts.push(codeBlock);
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < description.length) {
      parts.push(parse(description.slice(lastIndex)));
    }

    return parts;
  }

  function copyCode(code) {
    navigator.clipboard.writeText(code).then(() => {
      toast.success("Code copied to clipboard!");
    });
  }

  function openExternal(code) {
    const win = window.open("", "_blank", "width=800,height=600,resizable=yes");
    win.document.write(`<pre>${code}</pre>`);
    win.document.close();
  }

  function openRow(code, event) {
    const button = event.target;
    const container = button.closest(".code-container");
    const preTag = container.querySelector("pre");

    if (!preTag) return;
    if (preTag.dataset.original) {
      preTag.innerHTML = preTag.dataset.original;
      preTag.removeAttribute("data-original");
    } else {
      preTag.dataset.original = preTag.innerHTML;
      preTag.innerHTML = decode(code);
    }
  }

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    fetchData();
    fetchConfiguration();
  }, []);

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  const seriesData = data.filter((item) => item.tutorial_series_name == id);

  let tutorialSeriesName = seriesData.map((item) => item.tutorial_series_name);
  let tutorialSeriesDescription = seriesData.map((item) => (
    // dangerouslySetInnerHTML={renderHTML(item.tutorial_series_description)}
    <div style={{ fontFamily: "sofia" }}>
      {renderDescription(
        item?.tutorial_series_description || "<p>No description available</p>"
      )}
    </div>
  ));

  let tutorialSeriesImg = seriesData?.map((item) => (
    <img
      src={item.tutorial_series_image_b64}
      style={{ height: "300px", width: "400px" }}
      alt={item.tutorial_series_name}
    />
  ));
  console.log(tutorialSeriesImg);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10 font_family mb-4">
          <div class="card mt-5 p-2">
            <div
              className="card-header text-center"
              style={{ background: "#173a9b" }}
            >
              <h3 className="card-title text-light">{tutorialSeriesName[0]}</h3>
            </div>
            <h5 className="card-title mt-3">{tutorialSeriesDescription[0]}</h5>
            <h5>{tutorialSeriesImg[0]}</h5>
            <b className="font_family fs-3">Lessons</b>
            {seriesData.map((item, index) => (
              <div className="col-sm-12">
                {/* ************************* */}
                <Helmet>
                  <title>{tutorialSeriesName[0]}</title>
                  <meta
                    name="series_meta_title"
                    content={`${item.series_meta_title || metaTitle}`}
                  />
                  <meta
                    name="series_meta_keywords"
                    content={`${item.series_meta_keywords || metaKeywords}`}
                  />
                  <meta
                    name="series_meta_description"
                    content={`${
                      item.series_meta_description || metaDescription
                    }`}
                  />
                </Helmet>

                {/* ********************************** */}
                {index > 0}
                <div>
                  {item?.lesson_title?.split(",")?.map((title) => (
                    <Link
                      to={`/less/${item.lesson_id}`}
                      state={{ item }}
                      style={{ color: "#173a9b", textDecoration: "none" }}
                    >
                      <span className="fs-5">>{title}</span>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-sm-1"></div>
      </div>
      <ToastContainer />
    </div>
  );
}
