export const dataFormatter=(inputDate1)=>{
    const inputDate = new Date(inputDate1);


const dd = String(inputDate.getDate()).padStart(2, '0');
const yyyy = inputDate.getFullYear();



const monthNames = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  // '01','02','03','04','05','06','07','08','09','10','11','12',
];
const monthName = monthNames[inputDate.getMonth()];
const formattedDate = `${dd}-${monthName}-${yyyy}`; 

return formattedDate
}