let config = {};

//Development*********************************

// config["API_BLOG"] = "http://localhost:5000/api/blog/";

// config["API_TUTORIAL"] = "http://localhost:5000/api/tutorial/";

//live*****************************

config["API_BLOG"] = "https://nodeapi.ilearnings.in/api/blog/";

config["API_TUTORIAL"] = "https://nodeapi.ilearnings.in/api/tutorial/";

export default config;
