// import "./App.css";
// import React, { useState, useEffect } from "react";
// import Header from "./Components/Pages/Header";
// import Home from "./Components/Pages/Home";
// import About from "./Components/Pages/About";
// import Contact from "./Components/Pages/Contact";
// import Blogpost from "./Components/Pages/Blogpost";
// import Tutorialseries from "./Components/Pages/Tutorialseries";
// import Footer from "./Components/Pages/Footer";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Readmore from "./Components/Pages/Readmore";
// import Lessions from "./Components/Pages/Lessions";
// import Tutorial from "./Components/Pages/Tutorial";
// import Comment from "./Components/Pages/Comment";
// import Searchpage from "./Components/Pages/Searchpage";
// import config from "./config/Config";
// import { Helmet } from "react-helmet";

// function App() {
//   const [data, setData] = useState([]);

//   function fetchConfiguration() {
//     fetch(`${config.API_BLOG}configuration`)
//       .then((result) => result.json())
//       .then((res) => {
//         setData(res);
//       })
//       .catch((err) => console.error("Error fetching configuration:", err));
//   }

//   useEffect(() => {
//     fetchConfiguration();
//   }, []);

//   return (
//     <div>
//       <Header data={data} />
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/contact" element={<Contact />} />
//         <Route path="/read/:id" element={<Readmore />} />
//         <Route path="/comment/:id" element={<Comment />} />
//         <Route path="/less/:id" element={<Lessions />} />
//         <Route path="/tutorial/:id" element={<Tutorial />} />
//         <Route path="/blog" element={<Blogpost />} />
//         <Route path="/blog/category/:id" element={<Blogpost />} />
//         <Route path="/blog/tag/:tag" element={<Blogpost />} />
//         <Route path="/blog/year/:year/month/:month" element={<Blogpost />} />
//         <Route path="/tutorialseries" element={<Tutorialseries />} />
//         <Route
//           path="/tutorialseries/technology/:id"
//           element={<Tutorialseries />}
//         />
//         <Route path="/search" element={<Searchpage />} />
//       </Routes>
//       <Footer />
//     </div>
//   );
// }

// export default App;

import "./App.css";
import React, { useState, useEffect } from "react";
import Header from "./Components/Pages/Header";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Contact from "./Components/Pages/Contact";
import Blogpost from "./Components/Pages/Blogpost";
import Tutorialseries from "./Components/Pages/Tutorialseries";
import Footer from "./Components/Pages/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Readmore from "./Components/Pages/Readmore";
import Lessions from "./Components/Pages/Lessions";
import Tutorial from "./Components/Pages/Tutorial";
import Comment from "./Components/Pages/Comment";
import Searchpage from "./Components/Pages/Searchpage";
import config from "./config/Config";

function App() {
  const [data, setData] = useState([]);

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setData(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    fetchConfiguration();
  }, []);

  return (
    <BrowserRouter>
      <Header data={data} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/read/:id" element={<Readmore />} />
        <Route path="/comment/:id" element={<Comment />} />
        <Route path="/less/:id" element={<Lessions />} />
        <Route path="/tutorial/:id" element={<Tutorial />} />
        <Route path="/blog" element={<Blogpost />} />
        <Route path="/blog/category/:id" element={<Blogpost />} />
        <Route path="/blog/tag/:tag" element={<Blogpost />} />
        <Route path="/blog/year/:year/month/:month" element={<Blogpost />} />
        <Route path="/tutorialseries" element={<Tutorialseries />} />
        <Route
          path="/tutorialseries/technology/:id"
          element={<Tutorialseries />}
        />
        <Route path="/search" element={<Searchpage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
